import React, { memo } from 'react';// useState, useEffect, useRef
// import _ from 'lodash';
// import { useSelector, useDispatch } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import { makeStyles, styled } from '@mui/styles'; // useTheme
// import useNotificationLoading from '@utils/useNotificationLoading';
import { Link as RouterLink } from 'react-router-dom';

import { Container, Grid, Accordion, AccordionSummary, AccordionDetails, Link, Hidden, Slide, Dialog } from '@mui/material';
import { FiArrowRight } from "react-icons/fi";
import { TiHome } from "react-icons/ti";
import { IoMail, IoQrCode } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import { HiLink } from "react-icons/hi";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import 'swiper/css/bundle';
// import { storePaths } from '@utils/Tools';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

const Home = memo(() => {
    // const theme = useTheme();
    // const styles = useStyles();
    // const { t, i18n } = useTranslation();
    // const isMountedRef = useRef(null);
    // let navigate = useNavigate();
    // let location = useLocation();
    // const dispatch = useDispatch();
    // const { addAlert, setLoading } = useNotificationLoading();

    const [expanded, setExpanded] = React.useState('panel1');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const navItems = [
        {
            title: 'Features',
            link: '/#features',
            target: '_top',
        },
        {
            title: 'Why Us?',
            link: '/#why',
            target: '_top',
        },
        {
            title: 'Get Started',
            link: '/#start',
            target: '_top',
        },
        {
            title: 'Guide',
            link: '/#guides',
            target: '_top',
        },
        {
            title: 'Faq',
            link: '/#faq',
            target: '_top',
        },
        {
            title: 'Contact',
            link: '/#contact',
            target: '_top',
        },

    ];

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };


    return (
        <div className='pos-relative'>
            <Hidden smDown>
            <div className='pos-absolute' style={{ width: 250, top: 150, left: 0, zIndex: -1 }}>
                <img src='/images/landing/bkg-left.png' alt="bkg" className='w-full' />
            </div>
            </Hidden>
            <div className='pos-absolute' style={{ width: 250, top: 0, right: 0, zIndex: -1 }}>
                <img src='/images/landing/bkg-right.png' alt="bkg" className='w-full' />
            </div>
            <div className='flex-col p-b-50' style={{ gap: 50 }}>
                <div className='p-t-50'>
                    <Container maxWidth="lg">
                        <Grid container className='flex-col-reverse' style={{ alignItems: 'center' }}>
                            <Grid item xs={12} sm={5}>
                                <div className='max-w-full pos-relative' style={{ width: 400, margin: '0 auto' }}>
                                    <div className='p-b-20 max-w-full cl-text txt-center-mobile pos-relative' style={{zIndex:2}}>
                                        <p className='fs-subheader p-b-5 cl-theme txt-upper inter-extrabold'>USDT Payment Gateway</p>
                                        <p className='txt-upper inter-extrabold fs-header p-b-10'>Simplify and Secure Payments Gateway</p>
                                        <p className='fs-content'>At BICPAY, we prioritize your security. Every transaction is encrypted and monitored in real-time to ensure the highest level of protection.</p>
                                    </div>
                                    <Link to="#start" target='_top' component={RouterLink} underline='none'>
                                        <div className='bor10 flex-sb-m p-tb-15 p-lr-25 txt-center clwhite center-mobile translateX' style={{ background: '#469DFF', width: 'fit-content' }}>
                                            <p className='p-r-20'>Get Started</p>
                                            <FiArrowRight />
                                        </div>
                                    </Link>
                                    <Hidden mdDown>
                                    <div className='pos-absolute' style={{width:80, right:-70, top:-40, transform:'rotate(20deg)'}}>
                                        <img src='/images/landing/usdt.png' alt="usdt" className='w-full'/>
                                    </div>
                                    {/* <div className='pos-absolute' style={{width:100, left:-200, bottom:-40, transform:'rotate(-20deg)'}}>
                                        <img src='/images/landing/usdt.png' alt="usdt" className='w-full'/>
                                    </div> */}
                                    </Hidden>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <div className='max-w-full' style={{ width: 450, margin: '0 auto' }}>
                                    <img src='/images/landing/interface.png' alt="interface" className='w-full' />
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <div className='p-t-30' id="features">
                    <Container>
                        <p className='fs-header txt-center cl-text p-b-24'><span className='cl-theme'>Features</span> of BICPAY</p>
                        <Grid container className='flex-col-reverse' style={{ alignItems: 'center' }}>
                            <Grid item xs={12} sm={5}>
                                <div className='max-w-full' style={{ width: 400, margin: '0 auto' }}>
                                    <p className='fs-title txt-center-mobile'>Secure Transactions</p>
                                    <div className='bg-theme divider-bar m-tb-10 center-mobile' />
                                    <p className='fs-content txt-justify'>At BICPAY, we prioritize your security. Every transaction is encrypted and monitored in real-time to ensure the highest level of protection against fraud and unauthorized access.</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <div className='max-w-full' style={{ width: 400, margin: '0 auto' }}>
                                    <img src='/images/landing/features1.png' alt="features" className='w-full' />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container style={{ alignItems: 'center' }}>
                            <Grid item xs={12} sm={5}>
                                <div className='max-w-full' style={{ width: 400, margin: '0 auto' }}>
                                    <img src='/images/landing/features2.png' alt="features" className='w-full' />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <div className='max-w-full' style={{ width: 400, margin: '0 auto' }}>
                                    <p className='fs-title txt-center-mobile'>Easy Integration</p>
                                    <div className='bg-secondary divider-bar m-tb-10 center-mobile' />
                                    <p className='fs-content txt-justify'>Integrating BICPAY into your business is simple and straightforward. Our user-friendly API and detailed documentation make it easy for businesses of any size to start accepting cryptocurrency payments.</p>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container className='flex-col-reverse' style={{ alignItems: 'center' }}>
                            <Grid item xs={12} sm={5}>
                                <div className='max-w-full' style={{ width: 400, margin: '0 auto' }}>
                                    <p className='fs-title txt-center-mobile'>Multi-Currency Support</p>
                                    <div className='bg-theme divider-bar m-tb-10 center-mobile' />
                                    <p className='fs-content txt-justify'>BICPAY supports a wide range of cryptocurrencies, allowing you to accept payments in popular coins like Bitcoin (BTC), Ethereum (ETH), and Tether (USDT), among others. This flexibility helps you cater to a broader audience.</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <div className='max-w-full' style={{ width: 400, margin: '0 auto' }}>
                                    <img src='/images/landing/features3.png' alt="features" className='w-full' />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container style={{ alignItems: 'center' }}>
                            <Grid item xs={12} sm={5}>
                                <div className='max-w-full' style={{ width: 400, margin: '0 auto' }}>
                                    <img src='/images/landing/features4.png' alt="features" className='w-full' />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <div className='max-w-full' style={{ width: 400, margin: '0 auto' }}>
                                    <p className='fs-title txt-center-mobile'>Instant Conversion</p>
                                    <div className='bg-secondary divider-bar m-tb-10 center-mobile' />
                                    <p className='fs-content txt-justify'>Automatically convert cryptocurrency payments into fiat currencies to minimize exposure to market volatility. BICPAY offers competitive conversion rates and instant settlement to your preferred currency.</p>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid container className='flex-col-reverse' style={{ alignItems: 'center' }}>
                            <Grid item xs={12} sm={5}>
                                <div className='max-w-full' style={{ width: 400, margin: '0 auto' }}>
                                    <p className='fs-title txt-center-mobile'>Global Accessibility</p>
                                    <div className='bg-theme divider-bar m-tb-10 center-mobile' />
                                    <p className='fs-content txt-justify'>Whether you're a small business or a global enterprise, BICPAY enables you to accept payments from customers around the world. Our gateway supports multiple languages and currencies to cater to a diverse clientele.</p>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={7}>
                                <div className='max-w-full' style={{ width: 400, margin: '0 auto' }}>
                                    <img src='/images/landing/features5.png' alt="features" className='w-full' />
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>


                <Container className='p-t-30' id="why">
                    <p className='fs-header txt-center cl-text p-b-24'><span className='cl-theme'>Why Choose</span> BICpay ?</p>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className='bor20 shadow-out bg-base p-tb-30 p-r-20 w-full flex-sb-m' style={{ minHeight: 200 }}>
                                <div className='bg-theme' style={{ width: 10, height: 80 }} />
                                <div className='w-full p-l-20'>
                                    <div className='flex-m'>
                                        <div style={{ width: 45 }}>
                                            <img src='/images/landing/choose-icon1.png' alt="icon" className='w-full' />
                                        </div>
                                        <p className='p-l-15 lh-12'><b>Cutting-Edge<br />Technology</b></p>
                                    </div>
                                    <p className='fs-content p-t-10'>BICPAY leverages the latest blockchain technology to provide fast, reliable, and secure payment processing. We continuously update our platform to stay ahead of industry trends and security threats.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className='bor20 shadow-out bg-base p-tb-30 p-r-20 w-full flex-sb-m' style={{ minHeight: 200 }}>
                                <div className='bg-secondary' style={{ width: 10, height: 80 }} />
                                <div className='w-full p-l-20'>
                                    <div className='flex-m'>
                                        <div style={{ width: 45 }}>
                                            <img src='/images/landing/choose-icon2.png' alt="icon" className='w-full' />
                                        </div>
                                        <p className='p-l-15 lh-12'><b>Dedicated<br />Support</b></p>
                                    </div>
                                    <p className='fs-content p-t-10'>Our customer support team is available 24/7 to assist you with any questions or issues. Whether you need help with integration, troubleshooting, or understanding your transaction data, we’re here to help.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className='bor20 shadow-out bg-base p-tb-30 p-r-20 w-full flex-sb-m' style={{ minHeight: 200 }}>
                                <div className='bg-secondary' style={{ width: 10, height: 80 }} />
                                <div className='w-full p-l-20'>
                                    <div className='flex-m'>
                                        <div style={{ width: 45 }}>
                                            <img src='/images/landing/choose-icon3.png' alt="icon" className='w-full' />
                                        </div>
                                        <p className='p-l-15 lh-12'><b>Cutting-Edge<br />Technology</b></p>
                                    </div>
                                    <p className='fs-content p-t-10'>BICPAY leverages the latest blockchain technology to provide fast, reliable, and secure payment processing. We continuously update our platform to stay ahead of industry trends and security threats.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className='bor20 shadow-out bg-base p-tb-30 p-r-20 w-full flex-sb-m' style={{ minHeight: 200 }}>
                                <div className='bg-theme' style={{ width: 10, height: 80 }} />
                                <div className='w-full p-l-20'>
                                    <div className='flex-m'>
                                        <div style={{ width: 45 }}>
                                            <img src='/images/landing/choose-icon4.png' alt="icon" className='w-full' />
                                        </div>
                                        <p className='p-l-15 lh-12'><b>Cutting-Edge<br />Technology</b></p>
                                    </div>
                                    <p className='fs-content p-t-10'>BICPAY leverages the latest blockchain technology to provide fast, reliable, and secure payment processing. We continuously update our platform to stay ahead of industry trends and security threats.</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>

                <div className='p-t-50 pos-relative' id="start" style={{ zIndex: 2 }}>
                    <Container>
                        <div className='bg-secondary bor25 p-lr-20 p-tb-40 txt-center pos-relative' style={{ boxShadow: '0px 12px 24px 0px rgba(255, 201, 67, 0.42)' }}>
                            <div style={{ width: 500, maxWidth: '90%', margin: '0 auto' }}>
                                <div className='p-b-20'>
                                    <p className='fs-header lh-13 p-b-10 txt-center-mobile'><b>Getting Started with BICPAY</b></p>
                                    <p className='fs-content txt-center-mobile'>Getting started with BICPAY is easy. Simply sign up for an account, integrate our API into your website, and start accepting cryptocurrency payments right away.</p>
                                </div>

                                {/* <div className='txt-center bor10 p-tb-20 p-lr-25 bgwhite translateY pointer' style={{ width: 'fit-content', minWidth: 230, margin: '0 auto', filter: 'drop-shadow(0px 12px 12px rgba(255, 255, 255, 0.25))' }}>
                                    <p><b>Sign Up Today</b></p>
                                </div> */}
                            </div>

                            <div className='pos-absolute coin-usdt'>
                                <img src="/images/landing/usdt.png" alt="usdt" className='w-full'/>
                            </div>
                            <div className='pos-absolute coin-bitcoin'>
                                <img src="/images/landing/bitcoin.png" alt="usdt" className='w-full'/>
                            </div>
                        </div>

                        <div id="guides" className='bor25 p-lr-20 p-t-20 p-b-40 m-t-80' style={{ boxShadow: '0px 0px 30px 0px rgba(89, 116, 168, 0.19)', backdropFilter: 'blur(8px)', background: '#ffffff5e' }}>
                            <div className='flex-sb-m flex-col-reverse max-w-full' style={{ width: 800, margin: '0 auto' }}>
                                <div className='max-w-full' style={{ width: 400 }}>
                                    <p className='fs-header p-b-10 txt-center-mobile'><b>Comprehensive Guides</b></p>
                                    <p className='fs-content txt-justify'>We provide detailed guides and tutorials to help you navigate the setup process and maximize the benefits of using BICPAY. Our resources are designed to make your experience as smooth as possible.</p>
                                </div>
                                <div className='max-w-full' style={{ width: 300 }}>
                                    <img src='/images/landing/compass.png' alt="compass" className='w-full' />
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>

                <div className='p-t-50 pos-relative' id="faq">
                    <Container className='pos-relative' style={{ zIndex: 2 }}>
                        <Grid container>
                            {/* <Grid item xs={12} sm={6}>
                                <div className='p-b-35'>
                                    <p className='fs-header p-b-10'>Frequently Asked Question</p>
                                    <p className='fs-content'>Have questions or need assistance? Reach out to us anytime!</p>
                                </div>
                                <div className='flex-c-m p-tb-12 p-lr-25 bor10 translateX pointer' style={{ border: '1px solid #222', width: 'fit-content' }}>
                                    <p className='p-r-15'>Contact Us</p>
                                    <FiArrowRight />
                                </div>
                            </Grid> */}
                            <Grid item xs={12} sm={12}>
                            <p className='fs-header txt-center p-b-25'>Frequently Asked Question</p>
                                <Accordion style={{ border: 'none', marginBottom: 20, boxShadow: '5px 5px 12px 0px rgba(0, 0, 0, 0.09)', background: '#469DFF', borderRadius: 15, color: '#fff', }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className='clwhite' />}
                                        aria-controls="panel1-content"
                                        id="panel1-header"
                                    // style={{boxShadow:'5px 5px 12px 0px rgba(0, 0, 0, 0.09)', background:'#469DFF', borderRadius:15, color:'#fff',}}
                                    >
                                        <p className='fs-18'><b>How to Get Started with BICPAY</b></p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='p-b-20'>
                                            <p className='p-b-3'><b>STEP 1 - Sign Up</b></p>
                                            <p>Create your BICPAY account on our platform with 0 setup fees</p>
                                        </div>
                                        <div className='p-b-20'>
                                            <p className='p-b-3'><b>STEP 2 - Integrate</b></p>
                                            <p>Use our easy-to-follow API documentation to integrate BICPAY into your website or application</p>
                                        </div>
                                        <div className='p-b-20'>
                                            <p className='p-b-3'><b>STEP 3- Receive Credentials</b></p>
                                            <p>Upon sign-up, you'll receive your Merchant ID and credentials</p>
                                        </div>
                                        <div className='p-b-20'>
                                            <p><b>FINAL - Start Accepting Payments</b></p>
                                            <p>With your Merchant ID and API credentials, you can start accepting cryptocurrency payments immediately</p>
                                        </div>
                                        <p>Our support team is available 24/7 to assist you through every step of the process</p>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion style={{ border: 'none', marginBottom: 20, boxShadow: '5px 5px 12px 0px rgba(0, 0, 0, 0.09)', background: '#469DFF', borderRadius: 15, color: '#fff', }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon className='clwhite' />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                    >
                                        <p className='fs-18'><b>How the Payment Process Works with BICPAY</b></p>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <div className='fs-content'>
                                            <div className='p-b-20'>
                                                <p className='p-b-3'><b>Customer Initiates Payment</b></p>
                                                <p>The customer selects cryptocurrency as their payment method at checkout.</p>
                                            </div>
                                            <div className='p-b-20'>
                                                <p className='p-b-3'><b>Payment Request</b></p>
                                                <p>BICPAY generates a payment request with the amount and a unique wallet address.</p>
                                            </div>
                                            <div className='p-b-20'>
                                                <p className='p-b-3'><b>Customer Sends Payment</b></p>
                                                <p>The customer sends the cryptocurrency to the provided wallet address.</p>
                                            </div>
                                            <div className='p-b-20'>
                                                <p className='p-b-3'><b>Transaction Verification</b></p>
                                                <p>BICPAY verifies the transaction on the blockchain.</p>
                                            </div>
                                            <p className='p-b-20'><b>Funds Settlement</b><br />After verification, funds are settled into the merchant's account. Settlement can be done either hourly or daily, based on the merchant's preference.</p>
                                            <p><b>Confirmation</b><br />Both the merchant and customer receive confirmation of the payment.</p>
                                        </div>
                                    </AccordionDetails>
                                </Accordion>

                            </Grid>
                        </Grid>
                    </Container>
                    <div className='pos-absolute bor50 bg-theme' style={{ height: 200, width: 200, filter: 'blur(100px)', bottom: -150, right: 0 }}></div>
                    <div className='pos-absolute bor50 bg-secondary' style={{ height: 200, width: 200, filter: 'blur(100px)', top: -120, left: 0 }}></div>
                </div>

                <Container className='p-t-50'  id="contact">
                    <Grid container>
                        <Grid item xs={12} sm={12} md={7}>
                            <div className='p-b-40 w-full'>
                                <p className='fs-header p-b-10 inter-extrabold txt-center-mddown'>Contact Us</p>
                                <p className='fs-content txt-center-mddown'>Have questions or need assistance? Reach out to us anytime!</p>
                            </div>
                            <div className='flex-m m-b-35'>
                                <div className='contact-icon gradient-blue'>
                                    <TiHome />
                                </div>
                                <div className='p-l-15'>
                                    <p className='fs-19 inter-bold'><b>Company Address</b></p>
                                    <p className='fs-16 p-t-5'>No. 9-2, Jalan Tasik Utama 3, Medan Niaga Tasik Damai,<br/>Lakefields Sungai Besi, 57100 Kuala Lumpur, Malaysia.</p>
                                </div>
                            </div>
                            <div className='flex-m m-b-35'>
                                <div className='contact-icon gradient-yellow'>
                                    <IoMail />
                                </div>
                                <div className='p-l-15'>
                                    <p className='fs-19 inter-bold'><b>Email</b></p>
                                    <p className='fs-16 p-t-5'>marketing@bicpay.com</p>
                                </div>
                            </div>
                            <div className='flex-m m-b-35'>
                                <div className='contact-icon gradient-green'>
                                    <IoLogoWhatsapp />
                                </div>
                                <div className='flex-m w-full'>
                                    <div className='p-l-15'>
                                        <p className='fs-19 inter-bold'><b>Whatsapp</b></p>
                                        <p className='fs-16 p-t-5'>wa.link/8uyb97</p>
                                    </div>
                                    <div className='flex-m p-l-50'>
                                        <div className='flex-c-m fs-20 clwhite bor5 translateY pointer m-r-15' onClick={handleClickOpen} style={{background:'#4ECF77', width:38, height:38}}>
                                            <IoQrCode/>
                                        </div>
                                        <Link to="https://wa.link/8uyb97" target="_blank" underline='none' component={RouterLink}>
                                            <div className='flex-c-m fs-20 clwhite bor5 translateY pointer ' style={{ background: '#4ECF77', width: 38, height: 38 }}>
                                                <HiLink />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                        <Hidden mdDown>
                        <Grid item xs={12} sm={12} md={5}>
                            <div className='max-w-full p-t-20' style={{ width: 450, margin: '0 auto' }}>
                                <img src='/images/landing/interface.png' alt="interface" className='w-full' />
                            </div>
                        </Grid>
                        </Hidden>
                    </Grid>
                </Container>

                <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <div className='bg-base p-all-25 bor15' style={{boxShadow:'inset rgb(255 255 255) 5px 5px 5px 0px, inset -3px -3px 5px 0 #18205352'}}>
                    <p className='txt-center fs-title cl-theme'>Scan the QR code</p>
                        <p className='txt-center fs-16 p-t-5'>Scan to start a WhatsApp chat.</p>
                        <div className='p-all-20 bor15 bg-base' style={{ width: 250, margin: '0 auto' }}>
                            <img src='/images/landing/qr-whatsapp2.png' alt="whatsapp" className='w-full' />
                        </div>
                        <p className='txt-center'>wa.link/8uyb97</p>
                    </div>
                </Dialog>
            </div>
            <div className='pos-relative' style={{ boxShadow: '0px -6px 20px 0px rgba(43, 85, 148, 0.03)', zIndex: 2, background: '#fff5', backdropFilter: 'blur(15px)' }}>
                <Hidden smDown>
                    <div className='max-w-full p-t-50' style={{ width: 120, margin: '0 auto' }}>
                        <img src='/images/public/logo.png' alt="logo" className='w-full' />
                    </div>
                    <div className='w-full flex-c-m p-b-20' >
                        {navItems.map((item) => (
                            <div key={item.title} >
                                <Link to={item.link} target={item.target} underline='none' component={RouterLink} >
                                    <div className='p-tb-20 txt-left p-lr-20 translateY'>
                                        <p className='cl-dark'><b>{item.title}</b></p>
                                    </div>
                                </Link>
                            </div>
                        ))}
                        {/* <Link to='/' underline='none' component={RouterLink} >
                            <div className='p-tb-10 p-lr-20 bg-theme bor10 translateY'>
                                <p className='clwhite'>Sign Up</p>
                            </div>
                        </Link> */}
                    </div>
                </Hidden>

                <Hidden smUp>
                    <div className='max-w-full p-lr-30 p-t-30' style={{ width: 150 }}>
                        <img src='/images/public/logo.png' alt="logo" className='w-full' />
                    </div>
                    <div className='p-b-30'>
                        {navItems.map((item) => (
                            <div key={item.title} >
                                <Link to={item.link} target={item.target} underline='none' component={RouterLink} >
                                    <div className='p-tb-20 p-lr-30 w-full flex-sb-m cl-text translateX'>
                                        <p>{item.title} </p>
                                        <FiArrowRight />
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </Hidden>
                <div className='p-t-35 p-b-30' style={{ borderTop: '1px solid #0002' }}>
                    <p className='txt-center fs-content'>© 2024 BICpay Copyright</p>
                </div>
            </div>
        </div>
    )
});

// const useStyles = makeStyles((theme) => ({
   
// }));

export default Home;